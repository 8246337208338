import { parse } from "@brillout/json-serializer/parse";
import { stringify } from "@brillout/json-serializer/stringify";
import { useRef } from "react";
import {
  QueryClient,
  QueryFunction,
  QueryKey,
  useQuery as useReactQuery,
  useQueryClient,
  UseQueryOptions,
  UseQueryResult,
} from "react-query";
import { useStream } from "react-streaming";

const className = "rq-ssr-data";

const getSsrData = (key: string) => {
  const els = Array.from(window.document.querySelectorAll(`.${className}`));
  for (const el of els) {
    if (!el.textContent) {
      throw new Error("No text content");
    }
    const data = parse(el.textContent) as any[];
    for (const entry of data) {
      if (typeof entry.key !== "string") {
        throw new Error("Invalid key");
      }
      if (entry.key === key) {
        const { value } = entry;
        return value;
      }
    }
  }
};

export function useQuery<
  TQueryFnData = unknown,
  TError = unknown,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey,
  TNoSSR extends "server" | "client" | "both" = "both"
>(
  queryKey: TQueryKey,
  queryFn: QueryFunction<TQueryFnData, TQueryKey>,
  options: Omit<
    UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>,
    "queryKey" | "queryFn"
  > & { mode?: TNoSSR } = {}
): UseQueryResult<TData, TError> & {
  data: TNoSSR extends "server" | "both" ? TData : TData | undefined;
} {
  const queryClient = useQueryClient() as QueryClient & {
    __ssr_entries: Set<string>;
  };
  const key = queryKey.toString();
  const done = useRef(false);
  const mode = options.mode || "both";
  const stream = useStream();

  if (
    !import.meta.env.SSR &&
    ["server", "both"].includes(mode) &&
    !done.current
  ) {
    // avoid setting the data from the useSsrData cache multiple times
    done.current = true;
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const data = getSsrData(key);
    if (data) {
      const existing = queryClient.getQueryData(queryKey);
      if (!existing) {
        queryClient.setQueryData(queryKey, data);
      }
    }
  }
  const enabled = options.enabled || true;

  const rq = useReactQuery<TQueryFnData, TError, TData, TQueryKey>(
    queryKey,
    queryFn,
    {
      retry: false,
      ...options,
      suspense: true,
      enabled:
        mode === "both"
          ? enabled
          : mode === "client"
          ? !stream && enabled
          : !!stream && enabled,
    }
  );

  if (import.meta.env.SSR && ["server", "both"].includes(mode) && stream) {
    // avoid sending the same key multiple times
    queryClient.__ssr_entries ??= new Set<string>();
    if (!queryClient.__ssr_entries.has(key)) {
      queryClient.__ssr_entries.add(key);
      const htmlChunk = `<script class="${className}" type="application/json">${stringify(
        [{ key, value: rq.data }]
      )}</script>`;
      stream.injectToStream(htmlChunk);
    }
  }

  //@ts-ignore
  return rq;
}
