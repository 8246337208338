// XXX:
// The exported functions in this file are public http endpoints.
// They need to be secured.

import { LocationCreateRequest } from "@digitalsurance/generali-api-client";
import ky from "ky";
import config from "server/configuration";
import { verifyToken } from "server/livekit.service";
import { videoCollabManagementApi, videoManagementApi } from "server/openapi";

export const getExpert = async (inviteId: string) => {
  const res = await videoCollabManagementApi.getInviteVideoCollabInvitesIdGet({
    id: inviteId,
  });
  const invite = res.data;
  const editor = invite.editors.length ? invite.editors[0] : null;
  return editor;
};

export const openInvite = async (inviteId: string) => {
  const res = await videoCollabManagementApi.getInviteVideoCollabInvitesIdGet({
    id: inviteId,
    userOpen: true,
  });
  const invite = res.data;
  return {
    id: invite.id,
    token: invite.token!,
    valid: !invite.invalidated,
  };
};

export const createLocation = async (
  token: string,
  locationCreateRequest: LocationCreateRequest
) => {
  const verifiedPayload = await verifyToken(token);
  const videoId = verifiedPayload.videoId;

  await videoManagementApi.createLocationVideosVideoIdLocationPost({
    locationCreateRequest,
    videoId,
  });
};

export const createRoom = async (token: string, inviteId: string) => {
  const res = await videoCollabManagementApi.getInviteVideoCollabInvitesIdGet({
    id: inviteId,
  });
  const invite = res.data;
  if (invite.invalidated) {
    return {
      invalidated: true,
    };
  }

  await ky.post(`${config.INTERNAL_COMMON_API_URL}/api/livekit/create-room`, {
    json: {
      token,
    },
  });

  return {};
};

export const closeRoom = async (token: string) => {
  await ky.post(`${config.INTERNAL_COMMON_API_URL}/api/livekit/close-room`, {
    json: { token },
  });
};
